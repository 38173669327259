import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Box,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const initialState = {
  password: "",
  confirmPassword: "",
};

const SetUpPassword = () => {
  const [formState, setFormState] = useState(initialState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const { userId } = location.state || {};

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);


  const validatePasswordStrength = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };


  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setConfirmPasswordError("");

    const { password, confirmPassword } = formState;


    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

 
    if (!password) {
      setPasswordError("Password is required");
      return;
    }

  
    if (!validatePasswordStrength(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, a number, and a special character."
      );
      return;
    }

   
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "/api/auth/setUpPassword",
        {
          userId,
          newPassword: password,
        }
      );

      setLoading(false);

      if (response.data.success) {
        toast({
          title: "Password Set Successfully",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(response.data.redirectTo);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error setting password:", error);
      setError("An error occurred while setting the password.");
    }
  };

  return (
    <Box maxWidth="400px" margin="0 auto" padding="20px">
      <p className="auth_p">Set Up your Password</p>

      <form onSubmit={handlePasswordSubmit}>
      
        <FormControl isInvalid={!!passwordError} mb={4}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Create your Password"
              value={formState.password}
              onChange={(e) =>
                setFormState({ ...formState, password: e.target.value })
              }
            />
            <InputRightElement>
              <Button
                variant="link"
                onClick={togglePasswordVisibility}
                size="sm"
              >
                {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </Button>
            </InputRightElement>
          </InputGroup>
          {passwordError && (
            <FormErrorMessage>{passwordError}</FormErrorMessage>
          )}
        </FormControl>

     
        <FormControl isInvalid={!!confirmPasswordError} mb={4}>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm your Password"
              value={formState.confirmPassword}
              onChange={(e) =>
                setFormState({ ...formState, confirmPassword: e.target.value })
              }
            />
            <InputRightElement>
              <Button
                variant="link"
                onClick={toggleConfirmPasswordVisibility}
                size="sm"
              >
                {showConfirmPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </Button>
            </InputRightElement>
          </InputGroup>
          {confirmPasswordError && (
            <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
          )}
        </FormControl>

        {error && <FormErrorMessage>{error}</FormErrorMessage>}

        <Button
          type="submit"
          width="100%"
          mt={4}
          isLoading={loading}
          loadingText="Setting Password..."
        >
          Set Password
        </Button>
      </form>
    </Box>
  );
};

export default SetUpPassword;
