import React, { useEffect, useState } from "react";
import CommonForm from "../../common/form.js";
import { AdminBioFormData } from "../../config/Index.js";
import {
  Box,
  Heading,
  FormLabel,
  Text,
  Button,
  Spinner,
  Stack,
  Alert,
  AlertIcon,
  Input,
  FormControl,
  FormErrorMessage,
  Image,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai"; // For Delete Icon
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

const initialState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  maritalStatus: "",
  height: "",
  complexion: "",
  qualification: "",
  jobDetails: "",
  income: "",
  father: "",
  mother: "",
  sisters: "",
  brothers: "",
  residence: "",
  nativePlace: "",
  motherTongue: "",
  dargahVisit: "",
  sect: "",
  weight: "",
  bloodGroup: "",
  age: "",
  gender: "",
  paymentStatus: "",
  role: "",
  images: [], // State for new images
};

const EditProfiles = () => {
  const [bioFormData, setBioFormData] = useState(initialState);
  const [existingImages, setExistingImages] = useState([]); // New state for existing images
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          setError("No authentication token found.");
          setLoading(false);
          return;
        }

        const { data } = await axios.get(
          "/api/admin/getAllProfiles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const selectedProfile = data.profiles.find((bio) => bio._id === id);

        if (selectedProfile) {
          setBioFormData((prev) => ({
            ...prev,
            ...selectedProfile,
            images: [],
          }));
          setExistingImages(selectedProfile.images || []);
        } else {
          setError("Profile not found.");
        }
      } catch (err) {
        setError("Error fetching profile data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [id]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const totalFiles = bioFormData.images.length + files.length;

    if (totalFiles > 5) {
      setFormErrors((prev) => ({
        ...prev,
        images: "You can upload up to 5 images only.",
      }));
      return;
    }

    setFormErrors((prev) => ({
      ...prev,
      images: "",
    }));

    setBioFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
    }));
  };

  const handleRemoveImage = (index) => {
    const updatedImages = bioFormData.images.filter((_, i) => i !== index);
    setBioFormData((prev) => ({
      ...prev,
      images: updatedImages,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const formData = new FormData();
    const dataToSubmit = { ...bioFormData };

    if (
      dataToSubmit.age === "" ||
      dataToSubmit.age === "null" ||
      isNaN(dataToSubmit.age)
    ) {
      dataToSubmit.age = null;
    } else {
      dataToSubmit.age = Number(dataToSubmit.age);
    }

    for (const key in dataToSubmit) {
      formData.append(key, dataToSubmit[key]);
    }

    if (bioFormData.images && bioFormData.images.length > 0) {
      for (let i = 0; i < bioFormData.images.length; i++) {
        formData.append("images", bioFormData.images[i]);
      }
    }

    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setError("No authentication token found.");
        setLoading(false);
        return;
      }

      const response = await axios.put(
        `/api/admin/updateProfile/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "Profile updated.",
          description: "Your profile has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setTimeout(() => {
          navigate("/admin/view");
        }, 1500);
      } else {
        setError(response.data.message || "Error updating profile");
      }
    } catch (err) {
      console.error("Error response:", err.response);
      console.error("Error details:", err);

      if (err.response) {
        setError(
          err.response.data?.message || "Error while updating the profile."
        );
      } else if (err.request) {
        setError("No response received from the server.");
      } else {
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Box
      maxWidth="600px"
      width="100%"
      margin="0 auto"
      padding="20px"
      borderWidth="1px"
      borderRadius="8px"
      boxShadow="lg"
      backgroundColor="gray.50"
      overflow="auto"
    >
      <Heading as="h2" size="lg" mb={4} textAlign="center" color="teal.600">
        Edit Your Bio Data
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      {loading ? (
        <Stack spacing={4} align="center">
          <Spinner size="lg" color="teal.500" />
          <Text fontSize="lg" color="teal.500">
            Loading...
          </Text>
        </Stack>
      ) : (
        <>
          <FormControl isInvalid={formErrors.images}>
            <FormLabel htmlFor="images">Upload Images (up to 5)</FormLabel>
            <Input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            {formErrors.images && (
              <FormErrorMessage>{formErrors.images}</FormErrorMessage>
            )}
          </FormControl>

          {bioFormData.images && bioFormData.images.length > 0 && (
            <Box mt={4}>
              <Text>Selected New Images:</Text>
              <Flex wrap="wrap" gap={4}>
                {bioFormData.images.map((image, index) => (
                  <Box
                    key={index}
                    border="1px solid #ddd"
                    padding={2}
                    borderRadius="md"
                    position="relative"
                  >
                    <Image
                      src={URL.createObjectURL(image)}
                      boxSize="100px"
                      objectFit="cover"
                    />
                    <IconButton
                      icon={<AiOutlineDelete />}
                      colorScheme="red"
                      position="absolute"
                      top="0"
                      right="0"
                      size="sm"
                      onClick={() => handleRemoveImage(index)}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          )}

          {existingImages.length > 0 && (
            <Box mt={4}>
              <Text>Uploaded Images:</Text>
              <Flex wrap="wrap" gap={4}>
                {existingImages.map((image, index) => (
                  <Box
                    key={index}
                    border="1px solid #ddd"
                    padding={2}
                    borderRadius="md"
                  >
                    <Image src={image} boxSize="100px" objectFit="cover" />
                  </Box>
                ))}
              </Flex>
            </Box>
          )}

          <CommonForm
            formControls={AdminBioFormData}
            formData={bioFormData}
            setFormData={setBioFormData}
            buttonText={"Update"}
            isLoading={loading}
            onSubmit={onSubmit}
          />
        </>
      )}

      <Button colorScheme="teal" onClick={() => navigate(-1)} mt={4}>
        Cancel
      </Button>
    </Box>
  );
};

export default EditProfiles;
