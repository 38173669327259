import React from "react";
// import { Button } from "../ButtonElement";
import { Membership } from "./PackagesPlans"; // Import Membership data

import {
  Container,
  Title,
  Subtitle,
  PlansContainer,
  PlanCard,
  PlanTitle,
  PlanHeading,
  PlanName,
  BenefitsList,
  Benefit,
  ButtonStyled,
} from "./ContributeElements";

const Contribute = () => {
  return (
    <Container>
      <div className="my-6">
        <Title className="font-Safari">Membership Plans</Title>
      </div>
      <Subtitle className="font-Safari">
        Upgrade your plan as per your customized requirements.<br />
        With a paid membership, you can seamlessly connect with your prospects and get more responses. Here are some key benefits:
      </Subtitle>

      {/* Removed BackgroundDiv here */}
      <PlansContainer>
        {/* Free Plan Card */}
        <PlanCard>
          <PlanTitle>
            <PlanHeading />
            <PlanName className="font-Safari">FREE</PlanName>
          </PlanTitle>
          <BenefitsList>
            {Membership[0].benefits.map((benefit, index) => (
              <Benefit key={index}>{benefit}</Benefit>
            ))}
          </BenefitsList>
          <ButtonStyled>Register Free</ButtonStyled>
        </PlanCard>

        {/* Premium Plan Card */}
        <PlanCard>
          <PlanTitle>
            <PlanHeading />
            <PlanName className="font-Safari">PAID</PlanName>
          </PlanTitle>
          <BenefitsList>
            {Membership[1].benefits.map((benefit, index) => (
              <Benefit key={index}>{benefit}</Benefit>
            ))}
          </BenefitsList>
          <ButtonStyled>Upgrade to Premium</ButtonStyled>
        </PlanCard>

        {/* Gold Plan Card */}
        {/* <PlanCard>
          <PlanTitle>
            <PlanHeading />
            <PlanName className="font-Safari">GOLD</PlanName>
          </PlanTitle>
          <BenefitsList>
            {Membership[2].benefits.map((benefit, index) => (
              <Benefit key={index}>{benefit}</Benefit>
            ))}
          </BenefitsList>
          <ButtonStyled>Upgrade to Gold</ButtonStyled>
        </PlanCard> */}
      </PlansContainer>
    </Container>
  );
};



export default Contribute;
