import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import axios from "axios";
import "./DetailsProfile.css";

const DetailsProfile = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Fetching profile with id:", id);
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          setError("No authentication token found.");
          setLoading(false);
          return;
        }

        const { data } = await axios.get(
          `/api/admin/getAllProfiles`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(data);

        if (data && data.profiles) {
          const filteredProfile = data.profiles.find(
            (profile) => profile._id === id
          );

          if (filteredProfile) {
            setProfile(filteredProfile);
          } else {
            setError("Profile not found.");
          }
        } else {
          setError("Profile data is empty.");
        }
      } catch (err) {
        setError("Error fetching profile data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [id]);

  const profileDetails = useMemo(() => {
    if (!profile) return "";
    return `
      Full Name: ${profile.fullName}
      Age: ${profile.age} years old
      Education: ${profile.education}
      Father: ${profile.father}
      Job Details: ${profile.jobDetails}
      Marital Status: ${profile.maritalStatus}
      Date of Birth: ${profile.dob}
      Height: ${profile.height} inches
      Weight: ${profile.weight} lbs
      Blood Group: ${profile.bloodGroup}
      Salary: ${profile.income}
      Mother Name: ${profile.mother}
      Sisters: ${profile.sisters}
      Brothers: ${profile.brothers}
      Residence: ${profile.residence}
      Native Place: ${profile.nativePlace}
      Mother Tongue: ${profile.motherTongue}
      Do you visit Dargah?: ${profile.dargahVisit}
      Sect: ${profile.sect || "N/A"}
      Qualification: ${profile.qualification || "N/A"}
      Complexion: ${profile.complexion || "N/A"}
    `;
  }, [profile]);

  const handleShareText = () => {
    navigator.clipboard
      .writeText(profileDetails)
      .then(() => {
        alert("Profile details copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const handleDownloadPDF = () => {
    const pdf = new jsPDF();
    let yPosition = 20;

    const addHeader = (text) => {
      pdf.setFontSize(16);
      pdf.setFont("helvetica", "bold");
      pdf.text(text, 15, yPosition);
      yPosition += 10;
      pdf.setFontSize(12);
      pdf.setFont("helvetica", "normal");
    };

    const addText = (label, text) => {
      pdf.setFont("helvetica", "bold");
      pdf.text(`${label}:`, 15, yPosition);
      pdf.setFont("helvetica", "normal");
      pdf.text(text, 55, yPosition);
      yPosition += 8;
    };

    const addTextWithWrap = (label, text) => {
      pdf.setFont("helvetica", "bold");
      pdf.text(`${label}:`, 15, yPosition);
      pdf.setFont("helvetica", "normal");
      const maxWidth = 140;
      const wrappedText = pdf.splitTextToSize(text, maxWidth);
      pdf.text(wrappedText, 55, yPosition);
      yPosition += wrappedText.length * 8;
    };

    if (profile.images?.length > 0) {
      const imgUrl = `/${profile.images[0]?.replace(/\\/g, "/")}`;
      const img = new Image();
      img.src = imgUrl;

      img.onload = () => {
        pdf.addImage(img, "JPEG", 15, yPosition, 60, 60);
        yPosition += 70;

        addHeader(`Profile Details for ${profile.fullName}`);
        addText("Full Name", profile.fullName);
        addText("Age", `${profile.age} years old`);
        addText("Marital Status", profile.maritalStatus);
        addText("Date of Birth", profile.dob);
        addText("Height", `${profile.height}`);
        addText("Weight", `${profile.weight}`);
        addText("Blood Group", `${profile.bloodGroup}`);
        addText("Job Details", profile.jobDetails);
        addText("Father's Name", profile.father);
        addText("Mother's Name", profile.mother);
        addTextWithWrap("Sisters", profile.sisters);
        addTextWithWrap("Brothers", profile.brothers);

        addText("Residence", profile.residence);
        addText("Native Place", profile.nativePlace);
        addText("Mother Tongue", profile.motherTongue);
        addText("Dargah Visit", profile.dargahVisit);
        addText("Sect", profile.sect || "N/A");
        addText("Qualification", profile.qualification || "N/A");
        addText("Complexion", profile.complexion || "N/A");

        pdf.save(`profile_${profile._id}.pdf`);
      };
    } else {
      addHeader(`Profile Details for ${profile.fullName}`);
      addText("Full Name", profile.fullName);
      addText("Age", `${profile.age} years old`);
      addText("Marital Status", profile.maritalStatus);
      addText("Date of Birth", profile.dob);
      addText("Height", `${profile.height}`);
      addText("Weight", `${profile.weight}`);
      addText("Blood Group", `${profile.bloodGroup}`);
      addText("Job Details", profile.jobDetails);
      addText("Father's Name", profile.father);
      addText("Mother's Name", profile.mother);
      addTextWithWrap("Sisters", profile.sisters);
      addTextWithWrap("Brothers", profile.brothers);

      addText("Residence", profile.residence);
      addText("Native Place", profile.nativePlace);
      addText("Mother Tongue", profile.motherTongue);
      addText("Dargah Visit", profile.dargahVisit);
      addText("Sect", profile.sect || "N/A");
      addText("Qualification", profile.qualification || "N/A");
      addText("Complexion", profile.complexion || "N/A");

      pdf.save(`profile_${profile._id}.pdf`);
    }
  };

  if (loading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section className="details-profile">
      <div className="profile-card" id="profile-card">
        <h2>
          {profile?.fullName
            ? `${profile.fullName}'s Profile`
            : "No Profile Data"}
        </h2>
        <div className="profile-image">
          <img
            src={
              profile?.profilePicture && profile.profilePicture !== "null"
                ? `/${profile.profilePicture}`
                : profile?.images?.[0]
            }
            alt={`${profile?.fullName}'s profile`}
          />
        </div>
        <div className="profile-details">
          <table>
            <tbody>
              {Object.entries({
                Age: `${profile?.age || "N/A"} years old`,
                "Marital Status": profile?.maritalStatus || "N/A",
                "Date of Birth": profile?.dob || "N/A",
                Height: `${profile?.height || "N/A"} inches`,
                "Weight (kgs)": `${profile?.weight || "N/A"}`,
                "Blood Group": profile?.bloodGroup || "N/A",
                "Job Details": profile?.jobDetails || "N/A",
                "Income (PA)": profile?.income || "N/A",
                "Father Name": profile?.father || "N/A",
                "Mother Name": profile?.mother || "N/A",
                Sisters: profile?.sisters || "N/A",
                Brothers: profile?.brothers || "N/A",
                Residence: profile?.residence || "N/A",
                "Native Place": profile?.nativePlace || "N/A",
                "Mother Tongue": profile?.motherTongue || "N/A",
                "Do you visit Dargah": profile?.dargahVisit || "N/A",
                Sect: profile?.sect || "N/A",
                Qualification: profile?.qualification || "N/A",
                Complexion: profile?.complexion || "N/A",
              }).map(([key, value]) => (
                <tr key={key}>
                  <td>
                    <strong>{key}:</strong>
                  </td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="button-group">
          <button
            className="btn-share"
            onClick={handleShareText}
            style={{ padding: "5px 10px", borderRadius: "8px" }}
          >
            Share as Text
          </button>
          <button
            className="btn-download"
            onClick={handleDownloadPDF}
            style={{ padding: "5px 10px", borderRadius: "8px" }}
          >
            Download as PDF
          </button>
        </div>
      </div>
    </section>
  );
};

export default DetailsProfile;
