import React from "react";
import {
  MatchesContainer,
  H1,
  Wrapper,
  Card,
  Icon,
  H2,
  P,
} from "./MatchElements";

import Icon1 from "../../../media/1.png";
import Icon2 from "../../../media/2.png";
import Icon3 from "../../../media/3.png";

const Matches = () => {
  return (
    <MatchesContainer id="matches">
      <H1>Your Matches</H1>
      <Wrapper className="flex flex-wrap gap-4">
        <Card className="flex flex-col justify-center items-left p-4 bg-[#ffff] rounded-lg h-50 w-64">
          <Icon src={Icon1} alt="" />
          <H2>Manually screened profiles</H2>
          <P className="text-sm font-semibold">
           Search by location, community, profession & more
          </P>
        </Card>

        <Card className="flex flex-col justify-center items-left p-4 bg-[#ffff] rounded-lg h-50 w-64">
          <Icon src={Icon2} alt="" />
          <H2>Verified by Personal Visit</H2>
          <P className="text-sm font-semibold">
            Special listing of profiles verified through personal visits
          </P>
        </Card>

        <Card className="flex flex-col justify-center items-left p-4 bg-[#ffff] rounded-lg h-50 w-64">
          <Icon src={Icon3} alt="" />
          <H2>Control over Privacy</H2>
          <P className="text-sm font-semibold">
            Restrict unwanted access to contact details & photos/videos
          </P>
        </Card>
      </Wrapper>
    </MatchesContainer>
  );
};

export default Matches;
