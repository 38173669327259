import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Flex,
  Stack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const initialState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  maritalStatus: "",
  height: "",
  complexion: "",
  qualification: "",
  jobDetails: "",
  income: "",
  father: "",
  mother: "",
  sisters: "",
  brothers: "",
  residence: "",
  nativePlace: "",
  motherTongue: "",
  dargahVisit: "",
  sect: "",
  age: "",
  images: null,
  password: "",
  weight: "",
  bloodGroup: "",
};

const steps = [
  {
    title: "Personal Details",
    fields: [
      {
        name: "fullName",
        label: "Full Name",
        type: "text",
        componentType: "input",
      },
      { name: "email", label: "Email", type: "email", componentType: "input" },
      {
        name: "phoneNumber",
        label: "Phone Number",
        type: "tel",
        componentType: "input",
      },
      {
        name: "dob",
        label: "Date of Birth",
        type: "date",
        componentType: "input",
      },
      { name: "age", label: "Age", type: "number", componentType: "input" },
      {
        name: "maritalStatus",
        label: "Marital Status",
        type: "select",
        componentType: "select",
        options: [
          { value: "single", label: "Single" },
          { value: "married", label: "Married" },
          { value: "divorced", label: "Divorced" },
          { value: "widowed", label: "Widowed" },
        ],
      },
    ],
  },
  {
    title: "Physical Details",
    fields: [
      { name: "height", label: "Height", type: "text", componentType: "input" },
      {
        name: "gender",
        label: "Gender",
        type: "select",
        componentType: "select",
        options: [
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "other", label: "Other" },
        ],
      },
      { name: "weight", label: "Weight", type: "text", componentType: "input" },
      {
        name: "bloodGroup",
        label: "Blood Group",
        type: "text",
        componentType: "input",
      },
      {
        name: "complexion",
        label: "Complexion",
        type: "select",
        componentType: "select",
        options: [
          { value: "fair", label: "Fair Skin" },
          { value: "medium", label: "Medium Skin" },
          { value: "olive", label: "Olive Skin" },
          { value: "brown", label: "Brown Skin" },
        ],
      },
      {
        name: "qualification",
        label: "Qualification",
        type: "text",
        componentType: "input",
      },
      {
        name: "jobDetails",
        label: "Job Details",
        type: "text",
        componentType: "input",
      },
      { name: "income", label: "Income", type: "text", componentType: "input" },
    ],
  },
  {
    title: "Family Details",
    fields: [
      {
        name: "father",
        label: "Father's Name",
        type: "text",
        componentType: "input",
      },
      {
        name: "mother",
        label: "Mother's Name",
        type: "text",
        componentType: "input",
      },
      {
        name: "sisters",
        label: "Number of Sisters",
        type: "textarea",
        componentType: "textarea",
      },
      {
        name: "brothers",
        label: "Number of Brothers",
        type: "textarea",
        componentType: "textarea",
      },
    ],
  },
  {
    title: "Additional Details",
    fields: [
      {
        name: "residence",
        label: "Residence",
        type: "text",
        componentType: "input",
      },
      {
        name: "nativePlace",
        label: "Native Place",
        type: "text",
        componentType: "input",
      },
      {
        name: "motherTongue",
        label: "Mother Tongue",
        type: "text",
        componentType: "input",
      },
      {
        name: "dargahVisit",
        label: "Do you visit Dargah?",
        type: "select",
        componentType: "select",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
      {
        name: "sect",
        label: "Maslak / Sect",
        type: "text",
        componentType: "input",
      },
      {
        name: "paymentStatus",
        label: "Payment Status",
        type: "select",
        componentType: "select",
        options: [
          { value: "paid", label: "Paid" },
          { value: "unpaid", label: "Unpaid" },
        ],
      },
      {
        name: "images",
        label: "Upload Images",
        type: "file",
        componentType: "input",
      },
    ],
  },
  { title: "Save Your File", fields: [] },
];

const AddProfiles = () => {
  const [bioFormData, setBioFormData] = useState(initialState);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleChange = (name) => (event) => {
    const { value, type, files } = event.target;
    setBioFormData((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    Object.keys(bioFormData).forEach((key) => {
      formData.append(key, bioFormData[key]);
    });

    try {
      await axios.post(
        "/api/admin/addNewProfile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      toast({
        title: "Success!",
        description: "Profile has been registered successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setBioFormData(initialState);
      navigate("/admin/view");
    } catch (error) {
      console.error("Error during form submission:", error);
      const errorMessage =
        error.response?.data?.message ||
        "There was an error submitting your data. Please try again.";
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () =>
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  const handleBack = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const isLastStep = currentStep === steps.length - 1;

  const renderInputsByComponentType = (controlItem) => {
    const value = bioFormData[controlItem.name] || "";
    switch (controlItem.componentType) {
      case "input":
        return (
          <Input
            name={controlItem.name}
            placeholder={controlItem.label}
            type={controlItem.type}
            value={controlItem.type !== "file" ? value : undefined}
            onChange={handleChange(controlItem.name)}
          />
        );
      case "textarea":
        return (
          <Textarea
            name={controlItem.name}
            placeholder={controlItem.label}
            value={value}
            onChange={handleChange(controlItem.name)}
          />
        );
      case "select":
        return (
          <Select
            name={controlItem.name}
            value={value}
            onChange={handleChange(controlItem.name)}
          >
            <option value="">Select {controlItem.label}</option>
            {controlItem.options.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <Flex direction="column" align="center" mt={10}>
      <Box
        as="form"
        w="100%"
        maxW="600px"
        p={6}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        onSubmit={onSubmit}
      >
        <Heading as="h2" size="lg" textAlign="center" mb={6}>
          Register Profile
        </Heading>
        {steps.map(
          (step, index) =>
            currentStep === index && (
              <Box key={index}>
                <Heading as="h4" size="md" mb={4}>
                  {step.title}
                </Heading>
                {step.fields.map((field) => (
                  <FormControl key={field.name} mb={4}>
                    <FormLabel>{field.label}</FormLabel>
                    {renderInputsByComponentType(field)}
                  </FormControl>
                ))}
              </Box>
            )
        )}

        <Stack direction="row" justify="space-between" mt={6}>
          <Button
            variant="outline"
            onClick={handleBack}
            disabled={currentStep === 0}
          >
            Back
          </Button>
          {isLastStep ? (
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={loading}
              loadingText="Submitting"
            >
              Submit
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              onClick={handleNext}
              disabled={currentStep === steps.length - 1}
            >
              Next
            </Button>
          )}
        </Stack>
      </Box>
    </Flex>
  );
};

export default AddProfiles;
