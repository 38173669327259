import React from "react";
import { Outlet } from "react-router-dom";
import "../../styles/auth/login-register.css";

const Layout = () => {
  return (
    <main
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f9f9f9",
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
      }}
    >
      <nav style={{ padding: "20px 40px" }}>
      <h1 size="md" style={{ fontFamily: "Dancing Script, system-ui", fontSize: "2rem" }}>Ss.shaadi.com</h1>

      </nav>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <section
          style={{
            borderRadius: "5px",
            backgroundColor: "var( --bg-color)",
            padding: "5rem 2rem",
            color: "#ffffff",
            maxWidth: "400px",
            margin: "0 auto",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.9)",
            zIndex: 1,
            display: "grid",
            width: "100%",
            overflowY: "",
          }}
        >
          <Outlet />
        </section>
      </div>
    </main>
  );
};

export default Layout;
