import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 3rem 1.5rem;
`;

const Title = styled.p`
  font-size: 3rem;
  font-weight: 500;
  font-family: Safari;
  color: #d9475c;
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: 1.125rem;
  font-family: Safari;
  color: gray;
  margin-bottom: 2rem;
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  padding: 2.5rem 0;

  @media (max-width: 768px) {
    flex-direction: column; /* Change to column on smaller screens */
    align-items: center; /* Center the cards */
  }
`;

const PlanCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ isPaid }) => (isPaid ? "#d9475c" : "white")};
  padding: 30px;
  border-radius: 5px;
  box-shadow: ${({ isPaid }) =>
    isPaid ? "0 4px 20px rgba(0,0,0,0.3)" : "0 2px 10px rgba(0,0,0,0.1)"};
  gap: 1rem;
  flex: 1;
  max-width: 300px;
  width: 100%; /* Ensure full width on smaller screens */

  @media (max-width: 768px) {
    max-width: none; /* Reset max-width to take full width in a single column */
  }
`;

const PlanTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PlanHeading = styled.div`
  height: 3px;
  width: 35px;
  background-color: ${({ isPaid }) => (isPaid ? "white" : "#d9475c")};
`;

const PlanName = styled.div`
  font-size: 2rem;
  color: ${({ isPaid }) => (isPaid ? "white" : "gray")};
  font-weight: 600;
  font-family: Safari;
`;

const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Safari;
  gap: 1rem;
  align-items: flex-start;
  flex-grow: 1;
`;

const Benefit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: .8rem;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: normal;
  gap: 0.5rem;
  position: relative;
  padding-left: 1.5rem;

  &::before {
    content: "➤"; /* Unicode arrow symbol */
    position: absolute;
    left: 0;
    color: #d9475c;
    font-size: 1rem;
  }

  &:hover {
    background-color: rgba(217, 71, 92, 0.1); /* Light red background on hover */
    color: #d9475c; /* Change text color on hover */
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

const ButtonStyled = styled.button`
  background-color: ${({ isPaid }) => (isPaid ? "white" : "#d9475c")};
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: ${({ isPaid }) => (isPaid ? "#d9475c" : "white")};
  font-size: 1rem;
  font-weight: 600;
  font-family: "Encode Sans Expanded", sans-serif;
  margin-top: 1rem;
`;

export {
  Container,
  Title,
  Subtitle,
  PlansContainer,
  PlanCard,
  PlanTitle,
  PlanHeading,
  PlanName,
  BenefitsList,
  Benefit,
  ButtonStyled,
};
