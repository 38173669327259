import React from "react";

import Header from "./Navbar/Layout";
import Footer from "./Footer/index";
import HeroSection from "../../Pages/user/HeroSection/index";
import Packages from "../../Pages/user/Contribute/index.js";
import Matches from "../../Pages/user/Matches/index.js";
import Discover from "../../Pages/user/Discover/index.js";
const Layout = () => {
  return (
    <>
      <Header />
      <main>
        <section id="hero">
          <HeroSection />
        </section>
        <section id="matches">
          <Matches />
        </section>
        <section id="discover">
          <Discover />
        </section>
        <section id="packages">
          <Packages />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
