import React, { useState } from "react";
import Video from "../../../media/Couple2.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";

import { Button } from "../ButtonElement";
import SampleSignup from "../sampleSignup";
// import SignUpModal from "../SignUpModal"; 

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
        </style>
        <HeroH1>Together Forever</HeroH1>
        <HeroP>Match making made easy. Find your Special Someone! ❤️</HeroP>
        <HeroBtnWrapper>
          <Button onMouseEnter={onHover} onMouseLeave={onHover} onClick={openModal}>
            Get Started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>

      {/* Sign Up Modal */}
      {/* <SignUpModal isOpen={isModalOpen} onClose={closeModal} /> */}
      <SampleSignup isOpen={isModalOpen} onClose={closeModal} />
    </HeroContainer>
  );
};

export default HeroSection;
