import React from "react";
import pagenotfound from "../media/pagenotfound.jpg";

const PageNotFound = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        objectFit: "cover",
        width: "100%",
      }}
    >
      <img src={pagenotfound} alt="Page Not Found" />
    </div>
  );
};

export default PageNotFound;
