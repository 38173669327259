import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  FormErrorMessage,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  IconButton,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { SignupSteps } from "../../config/Index";
// import apiURL from "../../common/apiURL";

const initialState = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  dob: "",
  maritalStatus: "",
  height: "",
  weight: "",
  bloodGroup: "",
  complexion: "",
  qualification: "",
  jobDetails: "",
  income: "",
  father: "",
  mother: "",
  sisters: "",
  brothers: "",
  residence: "",
  nativePlace: "",
  motherTongue: "",
  dargahVisit: "",
  sect: "",
  age: "",
  images: null,
  gender: "",
};

const SampleSignup = ({ isOpen, onClose }) => {
  const [bioFormData, setBioFormData] = useState(initialState);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const steps = SignupSteps;

  const handleClose = () => {
    setBioFormData(initialState);
    setCurrentStep(0);
    setErrors({});
    setTouched({});
    onClose();
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "dob":
        if (!value || new Date(value) > new Date()) {
          error = "Please enter a valid Date of Birth.";
        }
        break;
      case "phoneNumber":
        if (!isValidPhoneNumber(value, "US")) {
          error = "Please enter a valid phone number.";
        }
        break;
      case "sisters":
      case "brothers":
        if (value && value.split(" ").length > 200) {
          error = "Please enter less than 200 words.";
        }
        break;
      case "income":
        if (value && value <= 0) {
          error = "Income must be a positive number.";
        }
        break;
      case "password":
        if (value.length < 8) {
          error = "Password must be at least 8 characters long.";
        }
        break;
      case "confirmPassword":
        if (value !== bioFormData.password) {
          error = "Passwords do not match.";
        }
        break;
      default:
        if (!value) {
          error = "This field is required.";
        }
        break;
    }
    return error;
  };

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: touched[name] ? error : "" }));

    if (name === "dob") {
      const dob = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - dob.getFullYear();
      setBioFormData((prev) => ({ ...prev, age, [name]: value })); 
    } else if (name === "images") {
      setBioFormData((prev) => ({ ...prev, [name]: event.target.files }));
    } else {
      setBioFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleBlur = (name) => () => {
    setTouched((prev) => ({ ...prev, [name]: true }));
    const error = validateField(name, bioFormData[name]);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    const newErrors = {};
    Object.keys(bioFormData).forEach((key) => {
      const error = validateField(key, bioFormData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      for (const key in bioFormData) {
        if (bioFormData[key] instanceof FileList) {
          for (let i = 0; i < bioFormData[key].length; i++) {
            formData.append(key, bioFormData[key][i]);
          }
        } else {
          formData.append(key, bioFormData[key]);
        }
      }

      const response = await axios.post(`/api/register`, formData);
      console.log(response);
      setBioFormData(initialState);
      handleClose();
    } catch (error) {
      setErrors({
        global: "There was an error submitting your data. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    const newErrors = {};
    steps[currentStep].fields.forEach((controlItem) => {
      const error = validateField(
        controlItem.name,
        bioFormData[controlItem.name]
      );
      if (error) newErrors[controlItem.name] = error;
    });

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => setCurrentStep((prev) => prev - 1);
  const isLastStep = currentStep === steps.length - 1;

  function renderInputsByComponentType(controlItem) {
    const value = bioFormData[controlItem.name] || "";

    switch (controlItem.componentType) {
      case "input":
        return (
          <Input
            name={controlItem.name}
            placeholder={controlItem.label}
            type={controlItem.type}
            value={controlItem.type === "file" ? undefined : value}
            onChange={handleChange(controlItem.name)}
            onBlur={handleBlur(controlItem.name)}
          />
        );
      case "textarea":
        return (
          <Textarea
            name={controlItem.name}
            placeholder={controlItem.label}
            value={value}
            onChange={handleChange(controlItem.name)}
            onBlur={handleBlur(controlItem.name)}
          />
        );
      case "select":
        return (
          <Select
            placeholder={controlItem.label}
            value={value}
            onChange={handleChange(controlItem.name)}
            onBlur={handleBlur(controlItem.name)}
          >
            {controlItem.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        );
      default:
        return null;
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="500px" borderRadius="20px" boxShadow="lg">
        <ModalHeader textAlign="center">
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={() => {
              if (currentStep === 0) {
                handleClose();
              } else {
                handleBack();
              }
            }}
            variant="ghost"
            aria-label="Back"
            position="absolute"
            left={2}
            top={2}
          />
          Sign Up
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={2}>
            <Text
              fontSize="xl"
              fontWeight="bold"
              textAlign={"center"}
              textTransform={"capitalize"}
              fontStyle={"oblique"}
            >
              {steps[currentStep].title}
            </Text>

            <form onSubmit={onSubmit}>
              {steps[currentStep].fields.map((controlItem) => (
                <FormControl
                  key={controlItem.name}
                  mb={4}
                  isInvalid={!!errors[controlItem.name] && touched[controlItem.name]}
                >
                  <FormLabel>{controlItem.label}</FormLabel>
                  {renderInputsByComponentType(controlItem)}
                  {errors[controlItem.name] && touched[controlItem.name] && (
                    <FormErrorMessage>
                      {errors[controlItem.name]}
                    </FormErrorMessage>
                  )}
                </FormControl>
              ))}

              {errors.global && <Text color="red.500">{errors.global}</Text>}
              <Flex justifyContent="space-between" mt={4}>
                {currentStep > 0 && (
                  <Button onClick={handleBack} isDisabled={loading}>
                    Back
                  </Button>
                )}
                {!isLastStep ? (
                  <Button onClick={handleNext} isDisabled={loading}>
                    Next
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    Submit
                  </Button>
                )}
              </Flex>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SampleSignup;
