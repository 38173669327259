import React, { useEffect, useState } from "react";
import { FaExclamationCircle, FaSearch } from "react-icons/fa";
import { TbUserEdit } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Heading,
  Text,
  Grid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Button,
  Input,
  Select,
  Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

const MotionBox = motion(Box);
const MotionStat = motion(Stat);

const ViewProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [error, setError] = useState(null);
  const [genderCount, setGenderCount] = useState({ male: 0, female: 0 });
  const [subscriptionCount, setSubscriptionCount] = useState({
    subscribed: 0,
    unsubscribed: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [currentFilter, setCurrentFilter] = useState("");
  const profilesPerPage = 10;
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          navigate("/login");
          return;
        }

        const response = await axios.get(
          "/api/admin/getAllProfiles",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const filteredProfiles = response.data.profiles.filter(
          (profile) => profile.role !== "admin"
        );
        setProfiles(filteredProfiles);
        calculateCounts(filteredProfiles);
      } catch (err) {
        console.error("Fetch error:", err);
        setError("Failed to fetch profiles. Please try again.");
      }
    };
    fetchProfiles();
  }, [navigate]);

  const calculateCounts = (profiles) => {
    let maleCount = 0;
    let femaleCount = 0;
    let subscribedCount = 0;
    let unsubscribedCount = 0;

        profiles.forEach((profile) => {
      if (profile.gender?.toLowerCase() === "male") maleCount++;
      if (profile.gender?.toLowerCase() === "female") femaleCount++;
      if (profile.paymentStatus?.toLowerCase() === "paid") subscribedCount++;
      if (profile.paymentStatus?.toLowerCase() === "unpaid")
        unsubscribedCount++;
    });

    setGenderCount({ male: maleCount, female: femaleCount });
    setSubscriptionCount({
      subscribed: subscribedCount,
      unsubscribed: unsubscribedCount,
    });
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this profile?")) {
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          navigate("/login");
          return;
        }

        await axios.delete(
          `/api/admin/deleteProfile/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setProfiles((prevProfiles) =>
          prevProfiles.filter((profile) => profile._id !== id)
        );

        toast({
          title: "Profile deleted.",
          description: "The profile has been deleted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (err) {
        setError("Failed to delete profile. Please try again.");
        console.error(err);

        toast({
          title: "Deletion failed.",
          description: "There was an issue deleting the profile.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const totalPages = Math.ceil(profiles.length / profilesPerPage);

  const filteredAndSortedProfiles = profiles
    .filter((profile) =>
      profile.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((profile) => {
      if (currentFilter === "male") return profile.gender === "male";
      if (currentFilter === "female") return profile.gender === "female";
      if (currentFilter === "subscribed")
        return profile.paymentStatus === "paid";
      if (currentFilter === "unsubscribed")
        return profile.paymentStatus === "unpaid";
      return true;
    })
    .sort((a, b) => {
      if (sortOption === "fullName") {
        return a.fullName.localeCompare(b.fullName);
      } else if (sortOption === "age") {
        return a.age - b.age;
      }
      return 0;
    });

  const currentProfiles = filteredAndSortedProfiles.slice(
    (currentPage - 1) * profilesPerPage,
    currentPage * profilesPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const statsData = [
    {
      label: "Male Users",
      number: genderCount.male,
      helpText: "Total Male Users",
      bg: "teal.50",
      color: "teal.600",
      filter: "male",
    },
    {
      label: "Female Users",
      number: genderCount.female,
      helpText: "Total Female Users",
      bg: "pink.50",
      color: "pink.600",
      filter: "female",
    },
    {
      label: "Subscribed Users",
      number: subscriptionCount.subscribed,
      helpText: "Total Subscribed Users",
      bg: "green.50",
      color: "green.600",
      filter: "subscribed",
    },
    {
      label: "Unsubscribed Users",
      number: subscriptionCount.unsubscribed,
      helpText: "Total Unsubscribed Users",
      bg: "red.50",
      color: "red.700",
      filter: "unsubscribed",
    },
  ];

  return (
    <MotionBox
      padding={{ base: "10px", md: "20px" }}
      bg="gray.50"
      borderRadius="md"
      boxShadow="md"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {error && <Text color="red.500">{error}</Text>}

      <Flex mb={6} align="center" gap={4}>
        <Box flex="1" position="relative">
          <FaSearch
            style={{
              position: "absolute",
              left: "10px",
              top: "40%",
              transform: "translateY(-50%)",
              color: "gray",
            }}
          />
          <Input
            placeholder="Search by full name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
            width="300px"
            pl="40px"
            height="40px"
          />
        </Box>
        <Select
          placeholder="Sort by"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          mb={4}
          width="300px"
        >
          <option value="fullName">Full Name</option>
          <option value="age">Age</option>
        </Select>
      </Flex>

      <Grid
        templateColumns={{ base: "1fr", sm: "1fr 1fr", md: "repeat(4, 1fr)" }}
        gap={6}
        mb={6}
      >
        {statsData.map(
          ({ label, number, helpText, bg, color, filter }, index) => (
            <MotionStat
              key={index}
              bg={bg}
              borderRadius="md"
              boxShadow="md"
              padding="20px"
              whileHover={{ scale: 1.05 }}
            >
              <StatLabel fontFamily="Poppins" fontWeight="bold">
                <Button
                  onClick={() => setCurrentFilter(filter)}
                  variant="link"
                  color={color}
                >
                  {label}
                </Button>
              </StatLabel>
              <StatNumber color={color}>{number}</StatNumber>
              <StatHelpText>{helpText}</StatHelpText>
            </MotionStat>
          )
        )}
      </Grid>

      <Heading as="h2" size="lg" color="var(--main-color)" mb={4}>
        List of Profiles ({filteredAndSortedProfiles.length})
      </Heading>

      <Box overflowX="auto">
        <Table variant="striped" colorScheme="gray" width="100%">
          <Thead>
            <Tr>
              <Th textAlign="center" fontWeight="bold" fontSize="lg">
                #
              </Th>
              {[
                "Full Name",
                "Age",
                "Qualification",
                "Father",
                "Job Details",
                "Actions",
              ].map((header) => (
                <Th
                  key={header}
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="lg"
                >
                  {header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {currentProfiles.length > 0 ? (
              currentProfiles.map((profile, index) => (
                <Tr key={profile._id}>
                  <Td textAlign="center">
                    {(currentPage - 1) * profilesPerPage + index + 1}
                  </Td>
                  <Td textAlign="center">{profile.fullName}</Td>
                  <Td textAlign="center">{profile.age}</Td>
                  <Td textAlign="center">{profile.qualification}</Td>
                  <Td textAlign="center">{profile.father}</Td>
                  <Td textAlign="center">{profile.jobDetails}</Td>
                  <Td textAlign="center">
                    <Box display="flex" gap="5px" justifyContent="center">
                      <IconButton
                        aria-label="View Details"
                        icon={<FaExclamationCircle />}
                        colorScheme="blue"
                        onClick={() =>
                          handleNavigate(`/admin/details/${profile._id}`)
                        }
                        size="sm"
                      />
                      <IconButton
                        aria-label="Edit Profile"
                        icon={<TbUserEdit />}
                        colorScheme="yellow"
                        onClick={() =>
                          handleNavigate(`/admin/profile/${profile._id}`)
                        }
                        size="sm"
                      />
                      <IconButton
                        aria-label="Delete Profile"
                        icon={<MdDeleteForever />}
                        colorScheme="red"
                        onClick={() => handleDelete(profile._id)}
                        size="sm"
                      />
                    </Box>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="7" textAlign="center">
                  <FaExclamationCircle style={{ marginRight: "10px" }} />
                  No profiles found
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <IconButton
          onClick={handlePreviousPage}
          isDisabled={currentPage === 1}
          aria-label="Previous Page"
          icon={<span>&lt;</span>}
          mr={2}
          colorScheme="blue"
        />
        {Array.from({ length: totalPages }, (_, i) => (
          <Button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            variant={currentPage === i + 1 ? "solid" : "outline"}
            colorScheme="blue"
            mr={1}
          >
            {i + 1}
          </Button>
        ))}
        <IconButton
          onClick={handleNextPage}
          isDisabled={currentPage === totalPages}
          aria-label="Next Page"
          icon={<span>&gt;</span>}
          ml={2}
          colorScheme="blue"
        />
      </Box>
    </MotionBox>
  );
};

export default ViewProfiles;
