import React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { SiLinktree } from "react-icons/si";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  Wrapper,
  LinksContainer,
  LinksWrapper,
  LinkItems,
  LinkTitle,
  FooterLink,
  SocialMedia,
  SMwrap,
  Logo,
  Copy,
  SocialIcons,
  IconLink,
} from "./FooterElements";

const Footer = () => {
  const toggleName = () => {
    scroll.scrollToTop();
  };

  return (
    <div>
      <FooterContainer>
        <Wrapper>
          <LinksContainer>
            <LinksWrapper>
              <LinkItems>
                <LinkTitle>About Us</LinkTitle>
                <FooterLink to="">Testimonials</FooterLink>
                <FooterLink to="">How it works</FooterLink>
                <FooterLink to="">Terms of Services</FooterLink>
              </LinkItems>
              {/* 
            <LinkItems>
              <LinkTitle>Videos</LinkTitle>
              <FooterLink to="">Agency</FooterLink>
              <FooterLink to="">Ambassadors</FooterLink>
              <FooterLink to="">Investors</FooterLink>
              <FooterLink to="">Demo</FooterLink>
            </LinkItems> */}
            </LinksWrapper>

            <LinksWrapper>
              <LinkItems>
                <LinkTitle>Contact Us</LinkTitle>
                <FooterLink to="">Contact</FooterLink>
                <FooterLink to="">Support</FooterLink>
                <FooterLink to="">Destinations</FooterLink>
              </LinkItems>

              <LinkItems>
                <LinkTitle>Social Media</LinkTitle>
                <FooterLink>LinkedIn</FooterLink>
                <FooterLink>Instagram</FooterLink>
                <FooterLink>Twitter</FooterLink>
              </LinkItems>
            </LinksWrapper>
          </LinksContainer>

          <SocialMedia>
            <SMwrap>
              <Logo to="/user" onClick={toggleName}>
                Ss .shaadi.com
              </Logo>
              <Copy>© Ss - {new Date().getFullYear} All rights reserved.</Copy>

              <SocialIcons>
                <IconLink
                //  href="" target="_blank"
                >
                  <FaGithub />
                </IconLink>

                <IconLink
                // href=""
                // target="_blank"
                >
                  <FaTwitter />
                </IconLink>

                <IconLink
                // href=""
                // target="_blank"
                >
                  <FaLinkedin />
                </IconLink>

                <IconLink
                // href="" target="_blank"
                >
                  <SiLinktree />
                </IconLink>
              </SocialIcons>
            </SMwrap>
          </SocialMedia>
        </Wrapper>
      </FooterContainer>
    </div>
  );
};
export default Footer;
