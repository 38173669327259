import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button,
  Wrap,
  WrapItem,
  useDisclosure,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
  Image,
  Box,
  Grid,
  Divider,
  Stack,
} from "@chakra-ui/react";

const UserCard = ({ profile, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Open, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [paymentStatus, setpaymentstatus] = useState([]);

  const {
    fullName,
    age,
    jobDetails,
    height,
    phoneNumber,
    images,
    profilePicture,
    email,
  } = profile;

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No token found");
        }
        const { data } = await axios.get(
          "/api/user/getProfiles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCurrentUser(data.currentUser);
        setpaymentstatus(data.currentUser.paymentStatus);
      } catch (error) {
        console.error("Error fetching users:", error.response || error.message);
      } finally {
      }
    };
    fetchUserProfile();
  }, []);

  const renderProfileDetails = useMemo(() => {
    return (
      <Stack spacing={4}>
        <Box textAlign="center">
          <Image
            src={
              profilePicture && profilePicture !== "null"
                ? `/${profilePicture}`
                : images?.[0]
            }
            alt={`${fullName}'s profile image`}
            mb={4}
            borderRadius="full"
            boxSize="150px"
            objectFit="cover"
            mx="auto"
          />
        </Box>
        <Box>
          <Heading size="sm" color="blue.500">
            Personal Information
          </Heading>
          <Text>
            <strong>Full Name:</strong> {fullName || "N/A"}
          </Text>
          <Text>
            <strong>DOB:</strong> {profile.dob || "N/A"}
          </Text>
          <Text>
            <strong>Age:</strong> {age ? `${age} Yrs` : "N/A"}
          </Text>
          <Text>
            <strong>Height:</strong> {height || "N/A"}
          </Text>
          <Text>
            <strong>Weight:</strong> {profile.weight || "N/A"}
          </Text>
          <Text>
            <strong>Blood Group:</strong> {profile.bloodGroup || "N/A"}
          </Text>
        </Box>

        <Box>
          <Heading size="sm" color="blue.500">
            Family Information
          </Heading>
          <Text>
            <strong>Father:</strong> {profile.father || "N/A"}
          </Text>
          <Text>
            <strong>Mother:</strong> {profile.mother || "N/A"}
          </Text>
          <Text>
            <strong>Sisters:</strong> {profile.sisters || "N/A"}
          </Text>
          <Text>
            <strong>Brothers:</strong> {profile.brothers || "N/A"}
          </Text>

          <Text>
            <strong>Native Place:</strong> {profile.nativePlace || "N/A"}
          </Text>
        </Box>

        <Box>
          <Heading size="sm" color="blue.500">
            Additional Information
          </Heading>
          <Text>
            <strong>Mother Tongue:</strong> {profile.motherTongue || "N/A"}
          </Text>
          <Text>
            <strong>Dargah Visit:</strong> {profile.dargahVisit || "N/A"}
          </Text>
          <Text>
            <strong>Sect:</strong> {profile.sect || "N/A"}
          </Text>
        </Box>
      </Stack>
    );
  }, [profile, fullName, age, phoneNumber, profilePicture, height, images]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <>
      <Card
        w="100%"
        mx="auto"
        p={4}
        borderRadius="lg"
        boxShadow="lg"
        background="white"
        style={{ overflow: "hidden" }}
        _hover={{
          transform: "translateY(-5px)",
          boxShadow: "2xl",
          transition: "0.3s ease",
        }}
      >
        <CardHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bg="gray.50"
          p={4}
          borderRadius="lg"
        >
          <Heading size="md" fontWeight="bold" color="gray.700">
            {fullName}
          </Heading>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => onDelete(profile.id)}
            aria-label={`Delete ${fullName}`}
            variant="outline"
            _hover={{ background: "gray.100" }}
          >
            X
          </Button>
        </CardHeader>

        <CardBody>
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr",
            }}
            gap={6}
          >
            <Box position="relative" w="100%">
              <Image
                src={
                  profilePicture && profilePicture !== "null"
                    ? `/${profilePicture}`
                    : images?.[0]
                }
                alt={`${fullName}'s profile image`}
                boxSize={{ base: "100%", md: "200px" }}
                objectFit="cover"
                borderRadius="lg"
                maxWidth="100%"
                maxHeight="200px"
                style={{
                  objectPosition: "center",
                  transform: "translateY(5px) translateX(5px)",
                }}
                onClick={handleImageClick}
              />
              <Modal isOpen={Open} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{`${fullName}'s Profile Picture`}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Image
                      src={
                        profilePicture && profilePicture !== "null"
                          ? `/${profilePicture}`
                          : images?.[0]
                      }
                      alt={`${fullName}'s profile image`}
                      maxWidth="100%"
                      maxHeight="400px"
                      objectFit="contain"
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>

            <Box>
              <Text
                fontSize={{ base: "md", md: "lg" }}
                fontWeight="bold"
                color="gray.800"
              >
                <strong>Age:</strong> {age} Yrs
              </Text>
              <Text>
                <strong>Height:</strong> {height}
              </Text>
              <Text>
                <strong>Phone Number:</strong>{" "}
                {paymentStatus === "paid"
                  ? phoneNumber
                  : `${phoneNumber?.slice(0, 2) || ""}*******`}
              </Text>
              {paymentStatus === "paid" && (
                <Text>
                  <strong>Email:</strong> {email}
                </Text>
              )}
              <Text>
                <strong>Occupation:</strong> {jobDetails}
              </Text>
            </Box>
          </Grid>

          <Divider my={4} />

          <Wrap spacing={4} justify="center">
            <WrapItem>
              <Button
                colorScheme="blue"
                onClick={onOpen}
                aria-label={`View full profile of ${fullName}`}
                width={{ base: "100%", md: "auto" }}
              >
                View Full Profile
              </Button>
            </WrapItem>
            <WrapItem>
              <Button variant="outline" width={{ base: "100%", md: "auto" }}>
                Send Interest
              </Button>
            </WrapItem>
            <WrapItem>
              <Button variant="outline" width={{ base: "100%", md: "auto" }}>
                Send Mail
              </Button>
            </WrapItem>
            <WrapItem>
              <Button variant="outline" width={{ base: "100%", md: "auto" }}>
                Shortlist
              </Button>
            </WrapItem>
          </Wrap>

          {showAlert && (
            <Alert status="warning" mt={4} borderRadius="md" boxShadow="md">
              <AlertIcon />
              Please subscribe to view the full details.
              <Button colorScheme="teal" size="sm" ml={4}>
                <Link href="/plans" _hover={{ textDecoration: "underline" }}>
                  Subscribe Now
                </Link>
              </Button>
            </Alert>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{fullName}-Full Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderProfileDetails}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserCard;

