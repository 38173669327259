import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginFormControls } from "../../config/Index";
import CommonForm from "../../common/form";
import axios from "axios";
import SampleSignup from "../user/sampleSignup";
import { useToast } from "@chakra-ui/react";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [allUsers, setAllUsers] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();

  // useEffect(() => {
  //   const fetchAllUsers = async () => {
  //     try {
  //       const response = await axios.get(
  //         "http://localhost:5310/api/admin/getAllProfiles"
  //       );
  //       setAllUsers(response.data);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };

  //   fetchAllUsers();
  // }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      setErrors({ message: "Email and Password are required!" });
      return;
    }

    try {
      const response = await loginUser(formData);
      if (response.data.success) {
        toast({
          title: "Login Successful",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        const token = response.data.token;
        if (token) {
          localStorage.setItem("authToken", token);
        }

        const user = response.data.user;
        localStorage.setItem("currentUser", JSON.stringify(user));

        if (response.data.redirectTo) {
          navigate(response.data.redirectTo);
        } else {
          toast({
            title: "No Redirection Path",
            description: "No redirection path provided.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Login Failed",
          description:
            response.data.message || "Login failed. Check credentials.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

      if (response.status === 201) {
        toast({
          title: "Please Set Up Your Password",
          description: "You need to set up your password.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });

        navigate(response.data.redirectTo, {
          state: { userId: response.data.userId, email: response.data.email },
        });
      }
    } catch (error) {
      toast({
        title: "Login Error",
        description:
          error.response?.data?.message || "An error occurred during login.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loginUser = async (data) => {
    try {
      const response = await axios.post(
        "/api/auth/login",
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <p className="auth_p">Log In into your Account</p>
      <CommonForm
        formControls={loginFormControls}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        buttonText={"Login"}
        errors={errors}
      />

      {errors.message && (
        <p style={{ color: "red", textAlign: "center" }}>{errors.message}</p>
      )}

      <p className="auth_p2" style={{ marginTop: "15px" }}>
        Not yet Registered?
        <span
          onClick={openModal}
          style={{
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            marginLeft: "5px",
          }}
        >
          Sign Up
        </span>
      </p>

      <SampleSignup isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Login;
