import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  IconButton,
  Button,
  useDisclosure,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { AdminPages } from "../../config/Index";
import axios from "axios";

const Layout = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogout = async () => {
    try {
      const token =
        localStorage.getItem("authToken") ||
        sessionStorage.getItem("authToken");

      const response = await axios.post(
        "/api/auth/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");
      toast({
        title: "Logged out successfully.",
        description: response.data.message || "You have been logged out.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/auth/login");
    } catch (error) {
      toast({
        title: "Logout Failed",
        description:
          error.response?.data?.message ||
          "There was an error logging you out.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleLinkClick = () => {
    onClose();
  };

  return (
    <Flex
      height="100vh"
      bg="white"
      boxShadow="0 4px 15px rgba(0, 0, 0, 0.1)"
      direction={{ base: "column", md: "row" }}
    >
      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        width={{ base: "100%", md: "200px" }}
        padding="20px"
        bg="gray.50"
        borderColor="gray.400"
        transition="0.3s"
        boxShadow="15px 0px 17px -8px rgba(0,0,0,0.1)"
      >
        <nav>
          <Link to="/admin/view" onClick={handleLinkClick}>
            <Text
              fontWeight={600}
              color="#3C3D37"
              fontSize="16px"
              fontFamily="Poppins"
              textTransform="capitalize"
              fontVariant="small-caps"
            >
              Admin
            </Text>
            <Text
              fontSize="2xl"
              fontWeight={800}
              color="purple.600"
              fontFamily="Dancing Script, system-ui"
              textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
            >
              Ss.shaadi.com
            </Text>
          </Link>

          <VStack
            spacing={4}
            align="stretch"
            marginTop="50px"
            as="ul"
            listStyleType="none"
            padding={0}
          >
            {AdminPages.map((page, index) => (
              <Box as="li" key={index}>
                <Link to={page.path} onClick={handleLinkClick}>
                  <Text
                    padding="10px"
                    borderRadius="md"
                    _hover={{ bg: "#D1D2D3", color: "#3C3D37" }}
                    transition="0.2s"
                  >
                    {page.label}
                  </Text>
                </Link>
              </Box>
            ))}
          </VStack>

          <Button
            onClick={handleLogout}
            colorScheme="red"
            variant="outline"
            marginTop="30px"
            width="100%"
          >
            Logout
          </Button>
        </nav>
      </Box>
      <Flex
        flexGrow={1}
        direction="column"
        padding={{ base: "10px", md: "20px" }}
        color="black"
        overflowY="scroll"
      >
        <Flex align="center" justify="space-between" marginBottom="30px">
          <Link to="/admin/view" onClick={handleLinkClick} />
          <Flex align="center">
            <IconButton
              aria-label={isOpen ? "Close sidebar" : "Open sidebar"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              onClick={onToggle}
              display={{ base: "block", md: "none" }}
              marginLeft="10px"
              colorScheme="gray"
            />
          </Flex>
        </Flex>
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default Layout;
