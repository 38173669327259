import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";

function CommonForm({
  formControls,
  formData,
  setFormData,
  onSubmit,
  buttonText,
  errors = {},
}) {
  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function renderInputsByComponentType(controlItem) {
    const value = formData[controlItem.name] || "";

    switch (controlItem.componentType) {
      case "input":
      case "textarea":
        return (
          <Input
            name={controlItem.name}
            placeholder={controlItem.placeholder}
            id={controlItem.name}
            type={controlItem.type}
            value={value || ""}
            onChange={handleChange(controlItem.name)}
          />
        );

      case "select":
        return (
          <Select
            placeholder={controlItem.label}
            value={value}
            onChange={handleChange(controlItem.name)}
          >
            {controlItem.options?.map((optionItem) => (
              <option key={optionItem.id} value={optionItem.id}>
                {optionItem.label}
              </option>
            ))}
          </Select>
        );

      default:
        return (
          <Input
            name={controlItem.name}
            placeholder={controlItem.placeholder}
            id={controlItem.name}
            type={controlItem.type}
            value={value}
            onChange={handleChange(controlItem.name)}
          />
        );
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        {formControls.map((controlItem) => (
          <FormControl
            key={controlItem.name}
            mb={4}
            isInvalid={errors[controlItem.name]}
          >
            <FormLabel>{controlItem.label}</FormLabel>
            {renderInputsByComponentType(controlItem)}
            {errors[controlItem.name] && (
              <FormErrorMessage>{errors[controlItem.name]}</FormErrorMessage>
            )}
          </FormControl>
        ))}
      </div>
      <Button type="submit" width="100%" mt={4}>
        {buttonText || "Submit"}
      </Button>
    </form>
  );
}

export default CommonForm;
