import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  SimpleGrid,
  IconButton,
  Spinner,
  useColorModeValue,
  useToast,
  DrawerFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Select,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserCard from "./UserCard";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const UserView = () => {
  const [profiles, setProfiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ageRange, setAgeRange] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const bg = useColorModeValue("gray.100", "gray.900");

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(
          "/api/user/getProfiles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProfiles(response.data.profiles);
        setCurrentUser(response.data.currentUser);
      } catch (error) {
        console.error("Error fetching users:", error.response || error.message);
        toast({
          title: "Unauthorized",
          description: "You are not authorized to access this resource.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setCurrentUser(null);
    navigate("/auth/login");
  };

  const filteredProfiles = profiles
    .filter(
      (profile) =>
        profile.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        profile.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        profile.phoneNumber.includes(searchQuery)
    )
    .filter((profile) => {
      if (ageRange) {
        const age = parseInt(profile.age);
        if (ageRange === "20-40" && (age < 20 || age > 40)) return false;
        if (ageRange === "40-60" && (age < 40 || age > 60)) return false;
        if (ageRange === "60+" && age <= 60) return false;
      }
      if (maritalStatus && profile.maritalStatus !== maritalStatus) {
        return false;
      }
      return true;
    });

  const openDrawer = (user) => {
    setSelectedUser(user);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedUser(null);
  };

  return (
    <>
      <nav>
        <Flex
          as="header"
          align="center"
          justify="space-between"
          p={4}
          bg={bg}
          boxShadow="lg"
          direction={{ base: "column", md: "row" }}
        >
          <Flex align="center" mb={{ base: 4, md: 0 }}>
            <Avatar name="Company Logo" src="/logo.jpg" mr={2} />
            <Heading
              size="md"
              sx={{ fontFamily: "Dancing Script, system-ui", fontSize: "2rem" }}
            >
              Ss.shaadhi.com
            </Heading>
          </Flex>
          <Box
            display="flex"
            justifyContent={{ base: "flex-start", md: "center" }}
            width="100%"
          >
            <Input
              placeholder="Search profiles..."
              width="100%"
              maxWidth={{ base: "100%", md: "300px" }}
              variant="filled"
              bg="white"
              mx={4}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search profiles"
              padding="10px"
            />
          </Box>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              mt={{ base: 5, md: 0 }}
            >
              <Flex align="center" mt={{ base: 4, md: 0 }}>
                <Avatar
                  name={currentUser?.fullName}
                  src={
                    currentUser?.profilePicture &&
                    currentUser.profilePicture !== "null"
                      ? `/${currentUser.profilePicture}`
                      : currentUser?.images?.[0]
                  }
                  size="sm"
                  mr={2}
                />
                {currentUser?.fullName}
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => openDrawer(currentUser)}>
                Your Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </nav>

      {/* Filters */}
      <Box p={1} bg={bg}>
        <Flex justify="left" align="center" gap="20px" p="20px">
          <Box
            width={{ base: "100%", sm: "48%", md: "18%" }}
            p="10px"
            borderRadius="8px"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
            mb={{ base: 4, sm: 0 }}
          >
            <Select
              placeholder="Select Age Range"
              value={ageRange}
              onChange={(e) => setAgeRange(e.target.value)}
              bg="white"
              border="1px solid #ddd"
              borderRadius="8px"
              padding="1px"
              fontSize="16px"
              color="black"
              transition="border-color 0.3s"
              _focus={{ borderColor: "teal" }}
            >
              <option value="20-40">(20-40)</option>
              <option value="40-60">(40-60)</option>
              <option value="60+">(60+)</option>
            </Select>
          </Box>
          <Box
            width={{ base: "100%", sm: "48%", md: "18%" }}
            p="10px"
            borderRadius="8px"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
          >
            <Select
              placeholder="Select Marital Status"
              value={maritalStatus}
              onChange={(e) => setMaritalStatus(e.target.value)}
              bg="white"
              border="1px solid #ddd"
              borderRadius="8px"
              padding="1px"
              fontSize="16px"
              color="black"
              transition="border-color 0.3s"
              _focus={{ borderColor: "teal" }}
            >
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
              <option value="widowed">Widowed</option>
            </Select>
          </Box>
        </Flex>
      </Box>

      {/* Main Content */}
      <main>
        <Box p={4}>
          {loading ? (
            <Flex justify="center" align="center" height="100%">
              <Spinner size="lg" />
            </Flex>
          ) : filteredProfiles.length > 0 ? (
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
              spacing={4}
            >
              {filteredProfiles.map((profile) => (
                <UserCard
                  key={profile._id}
                  profile={profile}
                  openDrawer={openDrawer}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Box textAlign="center" mt={4}>
              <Heading size="md" fontFamily="'Poppins', sans-serif">
                No profiles found.
              </Heading>
            </Box>
          )}
        </Box>
      </main>

      {/* Profile Drawer */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={closeDrawer}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Your Profile</DrawerHeader>
          <DrawerBody>
            {selectedUser ? (
              <MotionBox
                p={4}
                bg="gray.50"
                borderRadius="md"
                shadow="md"
                animate={{ scale: [0.8, 1] }}
                transition={{ duration: 0.5 }}
              >
                <Flex align="center" justify="center" mb={4}>
                  <Avatar
                    size="xl"
                    src={
                      currentUser?.profilePicture &&
                      currentUser.profilePicture !== "null"
                        ? `/${currentUser.profilePicture}`
                        : currentUser?.images?.[0]
                    }
                    alt={`${currentUser.fullName}'s profile`}
                    borderWidth="2px"
                    borderColor="teal.500"
                  />
                </Flex>
                <Heading
                  size="lg"
                  textAlign="center"
                  color="teal.600"
                  mb={2}
                  fontFamily="'Lora', serif"
                >
                  {currentUser.fullName}
                </Heading>

                {currentUser?.paymentStatus === "paid" && (
                  <Box
                    bg="green.100"
                    color="green.800"
                    p={2}
                    borderRadius="md"
                    textAlign="center"
                    mb={4}
                    fontFamily="'Poppins', sans-serif"
                  >
                    Premium User
                  </Box>
                )}
                <Box>
                  <DetailRow label="Email:" value={currentUser.email} />
                  <DetailRow
                    label="Phone Number:"
                    value={currentUser.phoneNumber}
                  />
                  <DetailRow label="Date of Birth:" value={currentUser.dob} />
                  <DetailRow
                    label="Marital Status:"
                    value={currentUser.maritalStatus}
                  />
                  <DetailRow label="Height:" value={currentUser.height} />
                  <DetailRow label="Weight:" value={currentUser.weight} />
                  <DetailRow
                    label="Blood Group:"
                    value={currentUser.bloodGroup}
                  />
                  <DetailRow
                    label="Complexion:"
                    value={currentUser.complexion}
                  />
                  <DetailRow
                    label="Qualification:"
                    value={currentUser.qualification}
                  />
                  <DetailRow
                    label="Job Details:"
                    value={currentUser.jobDetails}
                  />
                  <DetailRow label="Income:" value={currentUser.income} />
                  <DetailRow
                    label="Father's Occupation:"
                    value={currentUser.father}
                  />
                  <DetailRow
                    label="Mother's Occupation:"
                    value={currentUser.mother}
                  />
                  <DetailRow label="Sisters:" value={currentUser.sisters} />
                  <DetailRow label="Brothers:" value={currentUser.brothers} />
                  <DetailRow label="Residence:" value={currentUser.residence} />
                  <DetailRow
                    label="Native Place:"
                    value={currentUser.nativePlace}
                  />
                  <DetailRow
                    label="Mother Tongue:"
                    value={currentUser.motherTongue}
                  />
                  <DetailRow
                    label="Dargah Visit:"
                    value={currentUser.dargahVisit}
                  />
                  <DetailRow label="Sect:" value={currentUser.sect} />
                  <DetailRow label="Age:" value={currentUser.age} />
                </Box>
              </MotionBox>
            ) : (
              <Flex justify="center" align="center" height="100%">
                <Spinner size="lg" />
              </Flex>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" colorScheme="teal" onClick={closeDrawer}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const DetailRow = ({ label, value }) => {
  return (
    <Box mt={2} fontFamily="'Poppins', sans-serif">
      <strong>{label}</strong> <span>{value}</span>
    </Box>
  );
};

export default UserView;
